import React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useCopy } from '@elevio/kb-kit/lib/hooks';

function ArticleActions() {
  const { onCopy, didCopy } = useCopy();
  return (
    <div className="article-actions">
      {/*<button onClick={(e) => onCopy(e)} className="article-actions-button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="article-actions-icon"
        >
          <path d="M9 1C7.89543 1 7 1.89543 7 3H6C5.20435 3 4.44129 3.31607 3.87868 3.87868C3.31607 4.44129 3 5.20435 3 6V20C3 20.7957 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7957 21 20V6C21 5.20435 20.6839 4.44129 20.1213 3.87868C19.5587 3.31607 18.7956 3 18 3H17C17 1.89543 16.1046 1 15 1H9ZM17 5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H17ZM9 4V5H15V4V3H9V4Z" />
        </svg>
        {didCopy && (
          <div className="article-actions-tooltip">
            <Trans i18nKey="copied">Copied</Trans>
          </div>
        )}
      </button>*/}
      <button onClick={window.print} className="article-actions-button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="article-actions-icon"
        >
          <path d="M5 2C5 1.44772 5.44772 1 6 1H18C18.5523 1 19 1.44772 19 2V8H20C20.7957 8 21.5587 8.31607 22.1213 8.87868C22.6839 9.44129 23 10.2043 23 11V16C23 16.7957 22.6839 17.5587 22.1213 18.1213C21.5587 18.6839 20.7957 19 20 19H19V22C19 22.5523 18.5523 23 18 23H6C5.44772 23 5 22.5523 5 22V19H4C3.20435 19 2.44129 18.6839 1.87868 18.1213C1.31607 17.5587 1 16.7956 1 16V11C1 10.2044 1.31607 9.44129 1.87868 8.87868C2.44129 8.31607 3.20435 8 4 8H5V2ZM7 18V21H17V18V15H7V18ZM19 17V14C19 13.4477 18.5523 13 18 13H6C5.44772 13 5 13.4477 5 14V17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 10 4 10H6H18H20C20.2652 10 20.5196 10.1054 20.7071 10.2929C20.8946 10.4804 21 10.7348 21 11V16C21 16.2652 20.8946 16.5196 20.7071 16.7071C20.5196 16.8946 20.2652 17 20 17H19ZM17 3V8H7V3H17Z" />
        </svg>
      </button>
      {/*<button className="article-actions-button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="article-actions-icon"
        >
          <path d="M18 3C16.8954 3 16 3.89543 16 5C16 5.34726 16.0885 5.67385 16.2442 5.95844C16.2544 5.97391 16.2642 5.98975 16.2737 6.00596C16.2831 6.02205 16.292 6.0383 16.3004 6.05468C16.6533 6.6222 17.2825 7 18 7C19.1046 7 20 6.10457 20 5C20 3.89543 19.1046 3 18 3ZM15.1581 7.81485C15.883 8.54668 16.8885 9 18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 5.37701 14.0522 5.74185 14.1497 6.08767L8.84193 9.18514C8.11703 8.45332 7.11146 8 6 8C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16C7.11167 16 8.11742 15.5465 8.84235 14.8144L14.1509 17.9079C14.0526 18.255 14 18.6214 14 19C14 21.2091 15.7909 23 18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C16.8902 15 15.886 15.452 15.1613 16.1819L9.8505 13.0871C9.9479 12.7415 10 12.3768 10 12C10 11.623 9.94784 11.2581 9.85034 10.9123L15.1581 7.81485ZM7.69977 10.9455C7.70813 10.9618 7.71698 10.978 7.72632 10.994C7.73573 11.0101 7.74553 11.0259 7.75569 11.0413C7.91145 11.326 8 11.6526 8 12C8 12.3473 7.91148 12.6739 7.75576 12.9585C7.74546 12.9742 7.73553 12.9902 7.726 13.0065C7.71675 13.0224 7.70799 13.0384 7.69971 13.0546C7.34681 13.6221 6.71753 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.71757 10 7.34688 10.3779 7.69977 10.9455ZM16 19C16 18.6749 16.0776 18.3678 16.2153 18.0964C16.24 18.064 16.2629 18.0296 16.284 17.9935C16.3042 17.9588 16.3221 17.9234 16.3377 17.8875C16.6966 17.3523 17.3072 17 18 17C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21C16.8954 21 16 20.1046 16 19Z" />
        </svg>
        </button>*/}
    </div>
  );
}

export default ArticleActions;
