import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import LoginLogout from './LoginLogout';

function Footer({ className }) {
  return (
    <footer className={cx('footer', className)} data-testid="footer">
      <div className="footer-wrapper">
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} Boyum Solutions
        </p>
        <nav className="footer-nav">
          <a className="footer-link" href="https://boyumsolutions.zendesk.com/auth/v2/login/registration">
            <Trans i18nKey="external-link">Support</Trans>
          </a>
          <a className="footer-link" href="https://download.boyum-it.com/">
                <Trans i18nKey="downloads">Downloads</Trans>
          </a>
          <a className="footer-link" href="https://app.boyumcloud.com/">
            <Trans i18nKey="sign-in">Sign In</Trans>
          </a>
          {/*<LoginLogout className="footer-link" />*/}
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
